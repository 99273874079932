import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ParallaxProvider } from 'react-scroll-parallax';

import theme from 'src/styles/theme';
import GlobalStyle from 'src/styles/global';
import store from 'src/redux/store';
import UpdateLanguage from 'src/plugins/language';
import ScrollTop from 'src/plugins/scrollTop';
import SEO from 'src/plugins/SEO';
import Analytics from 'src/plugins/Analytics/Analytics';
import RedirectByIP from 'src/plugins/RedirectByIP';

import App from './App';

ReactDOM.render(
  <>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <ParallaxProvider>
          <Router>
            <>
              <RedirectByIP />
              <SEO />
              <Analytics />
              <UpdateLanguage />
              <ScrollTop />
              <GlobalStyle />
              <App />
            </>
          </Router>
        </ParallaxProvider>
      </Provider>
    </ThemeProvider>
  </>,
  document.getElementById('app')
);
