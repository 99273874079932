import React from 'react';

export default {
  switchLang: '中文',
  menu: [
    {
      text: 'Home',
      title: (
        <>
          Modern
          <br />
          <span>Grandeur.</span>
          <br />
          Greenside
          <br />
          <span>Manor.</span>
        </>
      ),
    },
    {
      text: 'Philosophy',
      title: (
        <>
          Our Story
          <br />
          Beings with
          <br />
          <span>Art</span>
        </>
      ),
    },
    {
      text: 'Creation',
      title: (
        <>
          The Making
          <br />
          of a<br />
          <span>Masterpiece</span>
        </>
      ),
    },
    {
      text: 'Location',
      title: (
        <>
          The Timeless
          <br />
          Tale of
          <br />
          <span>Kerrisdale</span>
        </>
      ),
    },
    {
      text: 'Contact',
      title: (
        <>
          <span>Inquiries</span>
        </>
      ),
    },
    {
      text: 'Register',
      title: (
        <>
          <span>Register</span>
          <br />
          Your Interest
        </>
      ),
    },
  ],
  register: 'Register',
  home: {
    hero: {
      title: (
        <>
          Modern
          <br />
          <span>Grandeur.</span>
          <br />
          Greenside
          <br />
          <span>Manor.</span>
        </>
      ),
    },
    intro: {
      subTitle: 'soon to arrive',
      title: (
        <>
          <span>On the wings of Gryphon</span>, <br />a globally informed way of
          life arrives here, in Kerrisdale.
        </>
      ),
      text:
        'Across the world’s greatest cities, condominium living has evolved into a high art form. Now, Vancouver joins the echelons of world-class residential offerings, where modern innovation and timeless grandeur meet. In the quiet prestige of this stately westside neighbourhood, on the cusp of the boulevard parkway, yet connected to the city – here is cultured city life, with room to bloom. ',
    },
    linkBlock: {
      subtitle: 'Gryphon Development',
      title: (
        <>
          Art <span>Lives</span> Here
        </>
      ),
      body:
        'Gryphon brings 25 years of inspired global architecture and artist-led design expertise to this meticulously considered location.',
      button: 'Our Philosophy',
    },
    contentBlock: [
      {
        subtitle: 'A New Residential Experience',
        title: (
          <h2>
            The Making
            <br />
            of a
            <br />
            <span>Masterpiece</span>
          </h2>
        ),
        body: (
          <>
            Gryphon House represents the culmination of world travels – an
            examination of architecture in major cities, and deep contemplation
            of the finest ways to live.
          </>
        ),
        images: ['home/gryphon-house-storefront.jpg'],
        button: {
          label: 'See the Creation',
          path: '/creation',
        },
        settings: {
          template: 6,
          rowReverse: false,
        },
      },
      {
        subtitle: 'A Revered Sense of Place',
        title: (
          <h2>
            The Timeless
            <br />
            Tale of
            <br />
            <span>Kerrisdale</span>
          </h2>
        ),
        body: (
          <>
            Ancient oak trees, heritage homes, and a village of upscale charm.
            The city’s finest educational institutions. Plus, the modern
            evolution of beloved boulevard parkway.
          </>
        ),
        images: [
          'home/wall-of-flowers.jpg',
          'home/dress-shoes.jpg',
          'home/thomas-hobbs-florist.jpg',
          'home/afternoon-tea.jpg',
        ],
        button: {
          label: 'Discover the Location',
          path: '/location',
        },
        settings: {
          template: 1,
          rowReverse: true,
        },
      },
    ],
  },
  philosophy: {
    subheader: 'Philosophy',
    header: (
      <>
        Our Story
        <br />
        Begins with
        <br />
        <span>Art</span>
      </>
    ),
    art: {
      subTitle: 'The Art of Every Day',
      title: (
        <>
          For art to make a <span>significant impact</span> on the way we shape
          community, we must first <span>re-imagine its role</span> in the way
          we craft our homes.
        </>
      ),
      text: (
        <>
          Gryphon has created <span>three divisions</span> to deepen our focus
          on the art of living, exploring how we can immerse ourselves in a
          world of art, open our minds to the insights and perspectives of
          creators and thinkers around the world, and curate our experiences of
          each day.
        </>
      ),
    },
    linkBlock: {
      title: (
        <>
          The Making of a <span>Masterpiece</span>
        </>
      ),
      body:
        'With a globally inspired, collaborative approach to the art of living, Gryphon House becomes the brilliant realization of Kerrisdale’s prestigious evolution.',
      button: 'See the Creation',
    },
    contentBlock: [
      {
        title: (
          <h3>
            The Gryphon
            <br />
            <span>Philosophy</span>
          </h3>
        ),
        body: (
          <>
            Our story begins with art. For twenty-five years, our parent
            company, Jinray, changed the way we conceive of architecture.
            <br />
            <br />
            Jinray insisted on artistry in every element; offering true luxury
            with a creative mastery, changing both skylines and lifestyles in
            unexpected ways across Taiwan. Artists were invited to lead the
            conversation. Towers arose as new forms of art.
          </>
        ),
        images: [
          'philosophy/wooden-thin-panels.jpg',
          'philosophy/jinray-eurostar.jpg',
          'philosophy/jinray-artfair.jpg',
          'philosophy/jinray-resort.jpg',
        ],
        settings: {
          template: 2,
          rowReverse: true,
        },
      },
      {
        title: (
          <h3>
            On the <span>Wings</span>
            <br />
            of Gryphon
          </h3>
        ),
        body: (
          <>
            The powerful lion representing Jinray metamorphosed as the company
            took flight to traverse the globe and establish itself in an
            exceptional new location – that would become all the more
            extraordinary for future generations.
            <br />
            <br />
            As the legendary gryphon, half lion, half eagle, Gryphon Development
            delivers a global viewpoint on the very best in lifestyle and
            culture encompassing the world’s finest neighbourhoods – the Upper
            East Side, Chelsea, Mayfair. Kerrisdale.
          </>
        ),
        images: [
          'philosophy/wooden-wing-panels.jpg',
          'philosophy/westbury-living.jpg',
          'philosophy/musee-sculpture-lady.jpg',
          'philosophy/westbury-rooftop.jpg',
        ],
        // video: {
        //   label: 'Watch Corporate Video',
        //   file: '',
        // },
        settings: {
          template: 3,
          rowReverse: false,
        },
      },
    ],
    cards: [
      {
        logo: 'logo-atelier-white.png',
        logoSize: 2,
        body:
          'Our collaborative, artist-driven approach to real estate development. We harness the creative potential of a wide spectrum of world-class artists – their unique perspective and backgrounds – to create new living art forms. ',
        background: 'atelier-background.jpg',
      },
      {
        logo: 'logo-royal-curator-white.png',
        logoSize: 0,
        body:
          'Our latest manifestation of the art of living, in which your every need is attended to by a team of committed professionals dedicated to giving you the one thing that everyone desires more of: time. ',
        background: 'curator-background.jpg',
      },
      {
        logo: 'logo-musee-white.png',
        logoSize: 1,
        body:
          'A platform and gallery space that celebrates contemporary artists and their respective work.',
        background: 'musee-background.jpg',
      },
    ],
  },
  creation: {
    subheader: 'Creation',
    header: (
      <>
        The Making
        <br />
        of a<br />
        <span>Masterpiece</span>
      </>
    ),
    atelier: {
      subTitle: 'ATELIER GRYPHON',
      title: (
        <>
          We harness the creative potential of a wide spectrum of{' '}
          <span>world-class artists</span> – their unique perspective and
          backgrounds – to create <span>new living art forms.</span>
        </>
      ),
      block1: {
        title: 'Yamamoto Architecture',
        text:
          'Eminent architect and renowned sketch artist Taizo Yamamoto brings his artist’s eye to Atelier Gryphon, infusing both art and design into our thinking, our vision, and our city’s skyline.',
      },
      block2: {
        title: 'False Creek Design Group',
        text:
          'With nearly three decades of experience, False Creek Design Group informs our understanding of the beauty of function, and the complexity of good design from the inside out.',
      },
    },
    collage: {
      title: (
        <>
          Global <span>Sophistication, </span>
          <br />
          Set in Stone
        </>
      ),
      text:
        'British-inspired exterior cladding of European limestone creates a worldly sense of sophistication for the future of Kerrisdale. This distinguished stone is famed for its use in world monuments including Westminster Abbey, Buckingham Palace, and St Paul’s Cathedral. A historic elegance befitting an iconic presence, right here in Kerrisdale.',
    },
    contentBlock: [
      {
        title: (
          <h3>
            A Global
            <br />
            <span>Perspective</span>
          </h3>
        ),
        body: (
          <>
            From the world-class innovation of our parent company, Jinray, in
            Taiwan, to the unique collaboration of the Atelier Gryphon, to our
            travels across the globe in pursuit of new forms of inspiration,
            Gryphon seeks the finest of art, architecture, and lifestyle – all
            to create a globally informed way of life, right here in Kerrisdale.
          </>
        ),
        images: [
          'creation/west-blvd-aerial.jpg',
          'creation/paris-aerial.jpg',
          'creation/london-apartment-facade.jpg',
          'creation/london-bank-district.jpg',
        ],
        settings: {
          template: 3,
          rowReverse: true,
        },
      },
      {
        subtitle: 'Architecture',
        title: (
          <h3>
            Each home,
            <br />a <span>work of art.</span>
          </h3>
        ),
        body: (
          <>
            Inspired by the some of the finest examples of modern traditionalism
            in London, Gryphon House brings a transformative excellence to
            Kerrisdale.
            <br />
            <br />
            Yamamoto creates a classic design sensibility to complement the
            historic nature of the surrounding neighbourhood, while offering a
            new perspective to illustrate the future of a neighbourhood – and a
            new art form for the city.
          </>
        ),
        images: ['creation/taizo-yamamoto-architecture-plans.jpg'],
        settings: {
          template: 6,
          rowReverse: false,
        },
      },
      {
        subtitle: 'Interior design',
        title: (
          <h3>
            Each life, a modern
            <br /> <span>masterpiece.</span>
          </h3>
        ),
        body: (
          <>
            False Creek Design Group brings a quiet splendor to each element of
            Gryphon House. With inventive details, high design, and a level of
            finish rarely found anywhere in the world, Gryphon House becomes the
            brilliant realization of Kerrisdale’s prestigious evolution.
          </>
        ),
        images: [
          'creation/coffee-tables.jpg',
          'creation/fcdg-tim.jpg',
          'creation/fcdg-dale.jpg',
        ],
        settings: {
          template: 5,
          rowReverse: true,
        },
      },
    ],
    linkBlock: {
      title: (
        <>
          The Timeless
          <br /> Tale of
          <br /> <span>Kerrisdale</span>
        </>
      ),
      text:
        'Long established. Forever revered. The art of living well begins with location, in the enduring heart of Vancouver’s prestigious west side – where the transformative power of global inspiration brings new sophistication to a time-honoured way of life.',
      buttonLabel: 'Discover the Location',
    },
  },
  location: {
    subheader: 'Location',
    header: (
      <>
        The Timeless
        <br />
        Tale of
        <br />
        <span>Kerrisdale</span>
      </>
    ),
    contentBlock: [
      {
        title: (
          <h3>
            This is a place for
            <br />
            living—<span>a time</span>—
            <br />
            <span>honoured</span> way of life.
          </h3>
        ),
        body: (
          <>
            Big manses, rolling lawns, and generations of growth and becoming
            flavour Kerrisdale’s unmistakable aura of prestige.
            <br />
            <br />
            It’s a rare position in a revered westside neighbourhood. The finest
            schools in the region are right here. Cafés and restaurants,
            clothiers and bookshops coalesce to form a pocket of high culture in
            the heart of the city – and cater to the next chapter of urban life.
          </>
        ),
        images: [
          'location/house-in-treeline.jpg',
          'location/dress-in-shop.jpg',
          'location/students-in-uniform.jpg',
          'location/kerrisdale-clock.jpg',
        ],
        settings: {
          template: 1,
          rowReverse: true,
        },
      },
      {
        title: (
          <h3>
            Distinguished
            <br />
            by Academic
            <br />
            <span>Excellence</span>
          </h3>
        ),
        body: (
          <>
            From elite private schools to the city’s leading public schools, to
            one of the world’s best universities; this is a place where lives
            are shaped, and futures are built.
          </>
        ),
        images: ['location/school-stone-facade.jpg'],
        settings: {
          template: 6,
          rowReverse: false,
        },
      },
      {
        title: (
          <h3>
            A World-Class
            <br />
            Public <span>Promenade</span>
          </h3>
        ),
        body: (
          <>
            Just across West Boulevard from Gryphon House, the transformation of
            one of the lengthiest linear parks in the world brings to life a
            corridor of art installations, cafés and kiosks, community spaces,
            and breathtaking landscape design.
            <br />
            <br />
            This distinguished boulevard parkway forms a public promenade
            reminiscent of those found in the world’s most sophisticated urban
            centres – the exemplary New York Highline, Barcelona’s lively Las
            Ramblas, Avenue Montaigne in the evocative heart of Paris. At your
            doorstep.
          </>
        ),
        images: [
          'location/west-blvd-aerial-closeup.jpg',
          'location/newyork-highline.jpg',
          'location/barcelona-las-ramblas.jpg',
          'location/paris-champs-elysees.jpg',
        ],
        settings: {
          template: 4,
          rowReverse: true,
        },
      },
    ],
  },
  contact: {
    subheader: 'Contact',
    header: (
      <>
        <span>Inquiries</span>
      </>
    ),
    directions: 'Get Directions',
    vancouver: {
      title: 'vancouver sales centre',
    },
    hk: {
      title: 'HONG KONG office',
    },
  },
  footer: {
    register: {
      subheader: 'inquiries',
      header: (
        <>
          Discover the <br />
          <span>Gryphon House </span>
          <br />
          way of life.
        </>
      ),
      button: 'register now',
    },
    contact: {
      contact: 'contact',
      social: 'social',
      disclaimer:
        'This is not an offer for sale and is for information purposes only. An offer for sale may only be made in conjunction with a Disclosure Statement. E. & O.E. The above images or drawings are for illustration purposes only. The developer reserves the right to alter size, price, design and/or finishings at any time.',
    },
  },
  registerPage: {
    intro: {
      subTitle: 'Register',
      title: (
        <>
          Discover the
          <br />
          <span>Gryphon House</span>
          <br />
          way of life.
        </>
      ),
    },
    firstName: 'First Name*',
    lastName: 'Last Name*',
    email: 'Email*',
    phone: 'Phone Number',
    postal: 'postal code*',
    howHeard: 'how did you hear about us?',
    howHeardOptions: [
      {
        label: 'Signage',
        value: 'Signage',
      },
      {
        label: 'Walk/Drive-by',
        value: 'Walk/Drive-by',
      },
      {
        label: 'Online Advertising',
        value: 'Online Advertising',
      },
      {
        label: 'Print Advertising',
        value: 'Print Advertising',
      },
      {
        label: 'Family/Friend',
        value: 'Family/Friend',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
    realtor: 'are you a realtor?*',
    realtorOptions: [
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
    typeHome: 'Type of Home',
    typeHomeOptions: [
      {
        label: '2 Bedroom +',
        value: '2 Bedroom +',
      },
      {
        label: '3 Bedroom +',
        value: '3 Bedroom +',
      },
      {
        label: 'Townhome',
        value: 'Townhome',
      },
      {
        label: 'Penthouse',
        value: 'Penthouse',
      },
      {
        label: 'Retail (CRU)',
        value: 'Retail (CRU)',
      },
    ],
    checkbox:
      'I hereby consent to receive emails from Gryphon Development and their respective current and future affiliates and subsidiaries. This may include pricing, floor plans for current and future products, sales updates, event invitations, announcements, corporate newsletters, and other contact. I understand that I can unsubscribe at any time. *',
    submit: 'Submit',
    register: 'Register',
    thankYou:
      'Thank you for registering for Gryphon House. A representative will be in touch with you shortly.',
    backToHome: 'Back to Home',
  },
  thankyou: {
    title: (
      <>
        Thank you for registering for <span>Gryphon House.</span>
      </>
    ),
    text:
      'Your registration has been received and a representative will be in touch with you shortly.',
    button: 'back to homepage',
  },
};
