import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FadeUp from 'components/animations/FadeUp';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const TitleBlock = (props) => {
  const { subTitle, title, text, image } = props;
  return (
    <FadeUp>
      <Root>
        <h4 className="subheader moss">{subTitle}</h4>
        <h2 className="dark">{title}</h2>
        {text && <p>{text}</p>}
        {image && <img src={image} alt={subTitle} />}
      </Root>
    </FadeUp>
  );
};

TitleBlock.propTypes = {
  subTitle: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.object,
};

const Root = styled.div`
  position: relative;
  text-align: left;
  margin: 0 ${vwMobile(16)};
  h4 {
    margin-bottom: ${vwMobile(32)};
  }
  h2 {
    span {
      font-style: italic;
    }
    br {
      display: none;
    }
  }
  
  p {
    margin-top: ${vwMobile(20)};
    span {
      font-weight: 600;
    }
  }
  img {
    display: block;
    width: auto;
    height: ${vwMobile(250)};
    position: absolute;
    right: ${vwMobile(-40)};
    top: ${vwMobile(-80)};
  }
  @media ${media.tablet} {
    margin: 0 ${vwTablet(40)};
    h4 {
      margin-bottom: ${vwTablet(80)};
    }
    p {
      margin-top: ${vwTablet(40)};
      margin-left: ${vwTablet(238)};
      width: ${vwTablet(450)};
    }
    img {
      height: ${vwTablet(500)};
      right: ${vwTablet(-80)};
      top: ${vwTablet(-160)};
    }
  }
  @media ${media.desktop} {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    h4,
    h2 {
      text-align: center;
      br {
        display: inline-block;
      }
    }
    h2 {
      max-width: ${vwDesktop(805)};
    }
    h4 {
      margin-bottom: ${vwDesktop(80)};
    }
    p {
      margin-top: ${vwDesktop(40)};
      margin-left: ${vwDesktop(415)};
      width: ${vwDesktop(430)};
    }
    img {
      height: ${vwDesktop(500)};
      right: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export default TitleBlock;
