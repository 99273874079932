import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';

import GoldTitle from 'components/typography/GoldTitle';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Hero = (props) => {
  const { subheader, header, children } = props;

  return (
    <Root>
      <Heading>
        <h2 className="subheader">{subheader}</h2>
        <GoldTitle text={header} />
      </Heading>
      {children}
      <Line />
    </Root>
  );
};

export default Hero;

Hero.propTypes = {
  subheader: PropTypes.string,
  header: PropTypes.object,
  image: PropTypes.string,
  imageWidth: PropTypes.object,
};

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 90vh;
  overflow: hidden;

  @media ${media.tablet} {
    height: 100vh;
  }
  @media ${media.desktop} {
    height: 100vh;
  }
`;

const Heading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h2 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.moss};
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    ${vw('top', -100)}
  }
`;

const LineAnimation = keyframes`
  0% {
    transform: scaleY(0);
    transform-origin: top;
  }
  50% {
    transform: scaleY(1);
    transform-origin: top;
  }
  51% {
    transform-origin: bottom;
  }
  100% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
`;

const LineAnimationCss = css`
  animation-name: ${LineAnimation};
  animation-duration: 3s;
  animation-timing-function: ${({ theme }) => theme.animation.timingFunction};
  animation-iteration-count: infinite;
  animation-delay: 0s;
`;

const Line = styled.div`
  position:absolute;
  left:0;
  right:0;
  top: 70%;
  width: 1px;
  height: ${vwMobile(50)};
  margin: 0 auto;
  background-image: linear-gradient(
    179deg,
    #f5d696 -3%,
    #f4e4a8 21%,
    #edd397 47%,
    #deb66d 67%,
    #dea960 100%
  );
  ${LineAnimationCss}
  @media ${media.tablet} {
    bottom: ${vwTablet(110)};
    height: ${vwTablet(20)};
  }
  @media ${media.desktop} {
    bottom:${vwDesktop(30)};
    height: ${vwDesktop(50)};
    transform: scaleY(0);
  }
`;