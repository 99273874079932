import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import Intl from 'components/Intl';
import Lines from 'components/Lines';
import Kerrisdale from 'components/logos/Kerrisdale';
import Logo from 'components/logos/Logo';
import HoverOverlay from './HoverOverlay';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import { ScaleInCss, ScaleFadeInCss, FadeInCss } from 'src/styles/animations';

import data from 'src/data/menu';

const Overlay = (props) => {
  const { active, closeMenu, overlayRef } = props;

  const location = useLocation();

  const handleIsHome = () => {
    let path = location.pathname;
    path = path.split('/').join('');
    if (path === '' || path === 'sc' || path === 'tc') {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root active={active} ref={overlayRef}>
          <Wrapper>
            <Flex top>
              <StyledLines left active={active} />
              <Logo active={active} />
              <StyledLines active={active} />
            </Flex>
            <HoverOverlay closeMenu={closeMenu} active={active} />
            <Links>
              {data.map(
                (item, index) =>
                  !item.register && (
                    <StyledLink
                      to={getLocaleURL() + item.to}
                      active={active}
                      index={index}
                      className="menu-page light"
                      onClick={closeMenu}
                      isOnPage={
                        item.notOnHome
                          ? handleIsHome()
                          : location.pathname.includes(item.to)
                      }
                    >
                      {t.menu[index].text}
                    </StyledLink>
                  )
              )}
              <Language>
                <LocaleLink
                  to="/"
                  className="menu-language light"
                  onClick={closeMenu}
                  active={locale === 'en'}
                >
                  En
                </LocaleLink>
                <LocaleLink
                  to="/sc"
                  className="menu-language light"
                  onClick={closeMenu}
                  active={locale === 'sc'}
                >
                  简
                </LocaleLink>
                <LocaleLink
                  to="/tc"
                  className="menu-language light"
                  onClick={closeMenu}
                  active={locale === 'tc'}
                >
                  繁
                </LocaleLink>
              </Language>
            </Links>
            <Flex desktop bottom>
              <StyledLines left active={active} />
              <Kerrisdale active={active} />
              <StyledLines active={active} />
            </Flex>

            <Details key={active}>
              <a href="tel:604-620-8295" className="light">
                <p className="p2 light">604-620-8295</p>
              </a><br />
              <a
                href="mailto:sales@gryphonhousevancouver.com"
                className="light"
              >
                <p className="p2 light">sales@gryphonhousevancouver.com</p>
              </a>
            </Details>
          </Wrapper>
          <BlackBackground active={active} />
          <Background key={active} />
        </Root>
      )}
    </Intl>
  );
};

Overlay.propTypes = {
  active: PropTypes.bool,
  closeMenu: PropTypes.func,
  overlayRef: PropTypes.object,
};

const Root = styled.div`
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.45),
      rgba(0, 0, 0, 0.45)
    ),
    url(${require('src/assets/images/menu/hero-william-merritt-chase-painting.jpeg')});
  background-size: cover;
  background-position: center;
  position: fixed;
  left: 0;
  top: 0;
  opacity: ${(props) => (props.active ? 1 : 0)};
  pointer-events: ${(props) => (props.active ? 'all' : 'none')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: ${(props) =>
    `${props.active ? props.theme.animation.time : '0.3s'} ${
      props.theme.animation.timingFunction
    }`};
  padding-top: max(${vwMobile(45)}, 8vh);
  padding-bottom: max(${vwMobile(85)}, 15vh);
  z-index: 5;
  @media ${media.tablet} {
    padding-top: ${vwTablet(100)};
    padding-bottom: ${vwTablet(150)};
  }
  @media ${media.desktop} {
    width: auto;
    padding: 0;
    margin-left: ${vwDesktop(100)};
    top: 0;
    left: 0;
    transition: ${(props) =>
      props.active
        ? 'none'
        : `${props.theme.animation.time} ${props.theme.animation.timingFunction}`};
    background: transparent;
    opacity: ${(props) => (props.active ? 1 : 0)};
    pointer-events: ${(props) => (props.active ? 'all' : 'none')};
    z-index: 100;
    overflow: hidden;
  }
`;

const BlackBackground = styled.div`
  display: none;
  @media ${media.desktop} {
    display: block;
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    transform: ${(props) => (props.active ? 'scaleX(100%)' : 'scaleX(0)')};
    transform-origin: 50% 50%;
    transition: ${({ theme }) =>
      `${theme.animation.time} ${theme.animation.timingFunction}`};
  }
`;

const Background = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, .8),
      rgba(0, 0, 0, .4),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, .4),
      rgba(0, 0, 0, 0.8)
    ),
    url(${require('src/assets/images/menu/hero-william-merritt-chase-painting.jpeg')});
  background-size: cover;
  background-position: center;
  z-index: -2;
  @media ${media.desktop} {
    display: block;
    opacity: 0;
    ${ScaleFadeInCss}
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  @media ${media.desktop} {
    padding: 0 ${vwDesktop(60)};
    position: relative;
  }
`;

const Flex = styled.div`
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  display: ${(props) => (props.desktop ? 'none' : 'flex')};
  @media ${media.desktop} {
    display: flex;
    flex-direction: row;
    padding-top: ${(props) => props.top && vwDesktop(20)};
    padding-bottom: ${(props) => props.bottom && vwDesktop(52)};
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${media.desktop} {
    flex-direction: row;
    display: none;
  }
`;

const LocaleLink = styled(Link)`
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  transition: ${({ theme }) =>
    `${theme.animation.time} ${theme.animation.timingFunction}`};
`;

const StyledLines = styled(Lines)`

  @media ${media.desktop} {
    opacity: 0;
    
    ${(props) => props.active && ScaleInCss}
  }
`;

const Details = styled.div`
  text-align: center;
  a:first-of-type {
    margin-bottom: ${vwMobile(8)};
  }
  @media ${media.tablet} {
    a:first-of-type {
      margin-bottom: ${vwTablet(16)};
    }
  }
  @media ${media.desktop} {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: ${vwDesktop(36)};
    padding: 0 ${vwDesktop(60)};
    opacity: 0;
    ${FadeInCss}
    a {
      transition: ${({ theme }) =>
        `${theme.animation.time} ${theme.animation.timingFunction}`};
      &:hover {
        opacity: 0.8;
      }
    }
    a:first-of-type {
      margin-bottom: 0;
    }
  }
`;

const StyledLink = styled(Link)`
  /* margin-bottom: max(${vwMobile(23)}, 4vh); */
  margin-bottom: ${vwMobile(23)};
  position: relative;
  &:last-of-type {
    /* margin-bottom: max(${vwMobile(34)}, 6vh); */
    margin-bottom: ${vwMobile(34)};
  }
  &::after {
    content: '';
    width: 100%;
    position: absolute;
    bottom: ${vwMobile(-3)};
    left: 0;
    height: 1px;
    background-image: linear-gradient(
      93deg,
      #f5d696 26%,
      #f4e4a8 37%,
      #edd397 49%,
      #deb66d 58%,
      #dea960 73%
    );
    opacity: ${(props) => (props.isOnPage ? 1 : 0)};
    transition: ${({ theme }) =>
      `${theme.animation.time} ${theme.animation.timingFunction}`};
  }
  @media ${media.tablet} {
    margin-bottom: ${vwTablet(40)};
    &:last-of-type {
      margin-bottom: ${vwTablet(64)};
    }
    &::after {
      bottom: ${vwTablet(-3)};
    }
  }
  @media ${media.desktop} {
    margin-bottom: 0;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const Language = styled.div`
  display: flex;
  a:nth-of-type(2) {
    margin: 0 ${vwMobile(24)};
  }
  @media ${media.tablet} {
    a:nth-of-type(2) {
      margin: 0 ${vwTablet(24)};
    }
  }
  @media ${media.desktop} {
    display: none;
  }
`;

export default Overlay;
