import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';

const GoldTitle = (props) => {
  const { text, h2 } = props;
  return (
    <Root>
      {h2 ? 
        <H2
          {...props}
          style={{
            background: `url(${require('src/assets/images/general/gold-foil.jpg')})`,
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            ...props.style,
          }}
        >
          {text}
        </H2> 
        :
        <H1
          {...props}
          style={{
            background: `url(${require('src/assets/images/general/gold-foil.jpg')})`,
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            ...props.style,
          }}
        >
          {text}
        </H1>
      }
    </Root>
  );
};

GoldTitle.propTypes = {
  text: PropTypes.object,
  h2: PropTypes.bool,
};

const Root = styled.div``;

const headingCss = css`
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  color: transparent;
  background: url(${require('src/assets/images/general/gold-foil.jpg')});
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  text-align: center;
  white-space: nowrap;
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  span {
    font-style: italic;
  }
  &:after {
    // Temporary Safari Text Masking Line Bug Fix
    content: ".";
    display: inline-block;
    color: transparent;
    ${vw('font-size', 10)}
  }
`;

const H1 = styled.h1`
  ${headingCss}
`;

const H2 = styled.h2`
  ${headingCss}
  text-align: left;
`;

export default GoldTitle;
