import React, { Component } from 'react';

class RedirectByIP extends Component {
  onSuccess = (result) => {
    let ipCountry = result.country.iso_code;

    if (ipCountry.toLowerCase() === 'hk') {
      // redirect to hk site
      window.location.assign('https://hk.gryphonhousevancouver.com/');
    }
  };

  onError = (error) => {
    console.log(error);
  };

  componentDidMount() {
    if (window.geoip2) {
      window.geoip2.country(this.onSuccess, this.onError);
    }
  }

  render() {
    return <></>;
  }
}

export default RedirectByIP;
