import React from 'react';
import styled from 'styled-components';
import Intl from 'src/components/Intl';

import Page from 'components/Page';
import Hero from 'components/Hero';
import ContentBlock from 'components/ContentBlock';
import Illustration from 'components/Illustration';
import FullImage from 'components/FullImage';

import vw from 'src/styles/utils';
import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Location = () => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            <Page>
              <Hero subheader={t.location.subheader} header={t.location.header}>
                <HeroImage
                  src={require('src/assets/images/location/illustration-kerrisdale-stripped.png')}
                  alt=""
                />
              </Hero>
              <Space />
              <ContentBlock
                title={t.location.contentBlock[0].title}
                body={t.location.contentBlock[0].body}
                images={t.location.contentBlock[0].images}
                settings={t.location.contentBlock[0].settings}
              />
              <ContentBlock
                title={t.location.contentBlock[1].title}
                body={t.location.contentBlock[1].body}
                images={t.location.contentBlock[1].images}
                settings={t.location.contentBlock[1].settings}
                leftPadding
              >
                <Illustration
                  mobile={{
                    width: 263.5,
                    height: 150,
                    top: 640,
                    left: 112,
                  }}
                  tablet={{
                    width: 527,
                    height: 300,
                    top: 980,
                    left: 345,
                  }}
                  desktop={{
                    width: 527,
                    height: 300,
                    top: 360,
                    left: 938,
                  }}
                  distance={-120}
                  image="location/illustration-ubc.png"
                />
              </ContentBlock>
              <ContentBlock
                title={t.location.contentBlock[2].title}
                body={t.location.contentBlock[2].body}
                images={t.location.contentBlock[2].images}
                settings={t.location.contentBlock[2].settings}
              >
                <Illustration
                  mobile={{
                    width: 329,
                    height: 220,
                    top: 500,
                    left: -24,
                  }}
                  tablet={{
                    width: 658,
                    height: 440,
                    top: 600,
                    left: 0,
                  }}
                  desktop={{
                    width: 658,
                    height: 440,
                    top: -120,
                    left: 0,
                  }}
                  distance={30}
                  image="location/illustration-arbutus-greenway.png"
                />
              </ContentBlock>
              <FullImage
                image={require('src/assets/images/location/arbutus-greenway-rendering.jpeg')}
                alt="Greenway"
              />
              <Space large />
            </Page>
          </Root>
        );
      }}
    </Intl>
  );
};

export default Location;

const Root = styled.div``;

const HeroImage = styled.img`
  position: absolute;
  top: 42.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 100%;
  ${vw('max-width', 232, 463)}
  ${vw('max-height', 364.78, 728)}
  @media ${media.tablet} {
    top: 49%;
  }
`;

const Space = styled.div`
  ${(props) =>
    props.large ? vw('margin-top', 80, 0, 160) : vw('margin-top', 80)}
`;
