import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';

import vw, { vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Checkbox = () => {
  return (
    <Intl>
      {(t, { locale }) => (
        <Root>
          <input type="checkbox" name="checkbox" id="checkbox" />
          <span />
        <label htmlFor="checkbox" className={`footnote dark`}>
          {t.registerPage.checkbox}
        </label>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  position: relative;
  ${vw('margin-top', 40, 40, 30)}
  ${vw('margin-right', 0, 0)}
  ${vw('margin-bottom', 40, 40, 50)}
  ${vw('margin-left', 0, 0)}
  ${vw('padding-left', 24, 36)}
  input,
  span {
    position: absolute;
    top: 0;
    left: 0;
    ${vw('width', 16, 24)}
    ${vw('height', 16, 24)}
  }
  span {
    pointer-events: none;
    border: 1px solid ${({ theme }) => theme.color.darkBrown};
    &::after {
      content: '';
      background-color: ${({ theme }) => theme.color.purple};
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      ${vw('width', 8, 12)}
      ${vw('height', 8, 12)}
    }
  }
  input {
    opacity: 0;
  }
  input:checked + span::after {
    opacity: 1;
  }

  @media ${media.tablet} {
    grid-column: 1 / 3;
    label {
      width: ${vwTablet(664)};
    }
  }
  @media ${media.desktop} {
    input,
    span {
      cursor: pointer;
    }
    label {
      cursor: pointer;
      width: ${vwDesktop(664)};
    }
  }
`;

export default Checkbox;
