import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useLocation, Link } from 'react-router-dom';

import Intl from 'components/Intl';
import GradientText from 'components/typography/GradientText';
import RoundButton from 'components/typography/RoundButton';
import Hamburger from './Hamburger';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Header = (props) => {
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const [isBottom, setIsBottom] = useState(false);
  const { handleMenu, isMenuOpen, handleClose } = props;
  const location = useLocation();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line
  }, []);

  let lastPosition = 0;

  const handleScroll = (e) => {
    // checks to see if user is scrolling up or down to hide the header or not
    let scrollPos = window.pageYOffset;

    if (scrollPos < 2) {
      setIsTop(true);
    } else {
      setIsTop(false);
    }

    if (scrollPos > lastPosition && e.target.scrollingElement.scrollTop > 0) {
      setIsScrollingDown(true);
    } else if (scrollPos < lastPosition) {
      setIsScrollingDown(false);
    }
    lastPosition = scrollPos;

    // checks to see if the page at the bottom to hide the register button
    if (
      e.target.scrollingElement.scrollTop >
      e.target.scrollingElement.scrollHeight - window.innerHeight * 1.5
    ) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  const handleIsHome = () => {
    let path = location.pathname;
    path = path.split('/').join('');
    if (path === '' || path === 'sc' || path === 'tc') {
      return true;
    } else {
      return false;
    }
  };

  const isRegisterHidden = () => {
    if (
      location.pathname.includes('register') ||
      location.pathname.includes('thank-you')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleLocaleClick = (e) => {
    e.stopPropagation();
    handleClose();
  };

  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <>
          <Root
            isHome={handleIsHome()}
            isScrollingDown={isScrollingDown}
            isMenuOpen={isMenuOpen}
          >
            <Background
              isMenuOpen={isMenuOpen}
              isHome={handleIsHome()}
              isTop={isTop}
            />
            <Hamburger handleClick={handleMenu} isMenuOpen={isMenuOpen} />
            <MobileLink to={`${getLocaleURL()}/register`} onClick={handleClose}>
              <p className="menu-cta">
                <GradientText>{t.register}</GradientText>
              </p>
            </MobileLink>
            <Desktop onClick={handleMenu}>
              <StyledLink
                to={`${getLocaleURL()}/`}
                onClick={(e) => e.stopPropagation()}
                isMenuOpen={isMenuOpen}
              >
                <img
                  src={require('src/assets/images/logos/gryphonhouse-adjusted-logo-gold.svg')}
                  alt="Gryphon House"
                />
              </StyledLink>
              <p className={`menu-action ${isMenuOpen ? 'dark' : 'light'}`}>
                {isMenuOpen ? 'Close' : 'Menu'}
              </p>
              <div>
                <LocaleLink
                  to="/"
                  className={`menu-language ${isMenuOpen ? 'dark' : 'light'}`}
                  active={locale === 'en'}
                  onClick={(e) => handleLocaleClick(e)}
                >
                  En
                </LocaleLink>
                <LocaleLink
                  to="/sc"
                  className={`menu-language ${isMenuOpen ? 'dark' : 'light'}`}
                  active={locale === 'sc'}
                  onClick={(e) => handleLocaleClick(e)}
                >
                  简
                </LocaleLink>
                <LocaleLink
                  to="/tc"
                  className={`menu-language ${isMenuOpen ? 'dark' : 'light'}`}
                  active={locale === 'tc'}
                  onClick={(e) => handleLocaleClick(e)}
                >
                  繁
                </LocaleLink>
              </div>
            </Desktop>
            <Register isBottom={isBottom}>
              {!isRegisterHidden() && (
                <RoundButton text={t.register} to={`${getLocaleURL()}/register`} />
              )}
            </Register>
          </Root>
        </>
      )}
    </Intl>
  );
};

Header.propTypes = {
  handleMenu: PropTypes.func,
  isMenuOpen: PropTypes.bool,
};

const Root = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: ${vwMobile(52)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${vwMobile(16)};
  color: white;
  z-index: 10;
  @media ${media.tablet} {
    height: ${vwTablet(80)};
    padding: 0 ${vwTablet(40)};
  }
  @media ${media.desktop} {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    bottom: auto;
    height: 100vh;
    width: ${vwDesktop(100)};
    padding: 0;
    margin-top: ${(props) => (props.isHome ? '100vh' : '0')};
    flex-direction: column;
    background-image: none;
    /* background-color: white; */
    z-index: 99;
  }
`;

const Register = styled.div`
  display: none;
  @media ${media.desktop} {
    display: block;
    position: absolute;
    top: ${vwDesktop(40)};
    left: ${`calc(100% + ${vwDesktop(1156)})`};
    opacity: ${(props) => (props.isBottom ? 0 : 1)};
    pointer-events: ${(props) => (props.isBottom ? 'none' : 'all')};
    transition: ${({ theme }) =>
      `${theme.animation.time} ${theme.animation.timingFunction}`};
  }
`;

const LocaleLink = styled(Link)`
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  transition: ${({ theme }) =>
    `${theme.animation.time} ${theme.animation.timingFunction}`};
  &:hover {
    opacity: 1;
  }
`;

const StyledLink = styled(Link)`
  opacity: ${(props) => (props.isMenuOpen ? 0 : 1)};
  pointer-events: ${(props) => (props.isMenuOpen ? 'none' : 'all')};
  transition: ${({ theme }) =>
    `${theme.animation.time} ${theme.animation.timingFunction}`};
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${require('src/assets/images/general/menu-bkg-mobile.jpg')});
  pointer-events: none;
  opacity: ${(props) =>
    props.isMenuOpen || (props.isHome && props.isTop) ? 0 : 1};
  z-index: -1;
  transition: ${({ theme }) =>
    `${theme.animation.time} ${theme.animation.timingFunction}`};
  @media ${media.tablet} {
    background-image: url(${require('src/assets/images/general/menu-bkg-tablet.jpg')});
  }
  @media ${media.desktop} {
    background-image: ${(props) =>
      props.isMenuOpen
        ? 'linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1))'
        : `linear-gradient(to bottom, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${require('src/assets/images/menu/hero-william-merritt-chase-painting.jpeg')})`};
    background-size: cover;
    background-position: center;
  }
`;

const Desktop = styled.div`
  display: none;
  @media ${media.desktop} {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: ${vwDesktop(40)} 0;
    cursor: pointer;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    p {
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      transition: ${({ theme }) =>
        `${theme.animation.time} ${theme.animation.timingFunction}`};
    }
    a {
      display: block;
      margin-bottom: ${vwDesktop(16)};
      transition: ${({ theme }) =>
        `${theme.animation.time} ${theme.animation.timingFunction}`};
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    img {
      width: ${vwDesktop(44)};
    }
  }
`;

const MobileLink = styled(Link)`
  @media ${media.desktop} {
    display: none;
  }
`;

export default Header;
