import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const social = [
  {
    to: 'https://www.facebook.com/gryphondevelopmentca/',
    alt: 'Facebook',
    image: require('src/assets/images/icons/icon-facebook.svg'),
  },
  {
    to: 'https://www.instagram.com/gryphondevelopment/',
    alt: 'Instagram',
    image: require('src/assets/images/icons/icon-ig.svg'),
  },
  {
    to: 'https://twitter.com/gryphon_dev',
    alt: 'Twitter',
    image: require('src/assets/images/icons/icon-twitter.svg'),
  },
];

const Info = () => {
  return (
    <Intl>
      {(t) => (
        <Root>
          <Wrapper>
            <a
              href="https://gryphonliving.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="logo"
            >
              <Logo
                src={require('src/assets/images/logos/gryphon-logo.svg')}
                alt="Gryphon"
              />
            </a>
            <Contact>
              <h4 className="subheader light">{t.footer.contact.contact}</h4>
              <a href="tel:604-620-8295">
                <p className="p2 light">604-620-8295</p>
              </a>
              <a href="mailto:sales@gryphonhousevancouver.com">
                <p className="p2 light">sales@gryphonhousevancouver.com</p>
              </a>
            </Contact>
            <Container>
              <h4 className="subheader light">{t.footer.contact.social}</h4>
              <Social>
                {social.map((item, index) => (
                  <a
                    href={item.to}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                  >
                    <Icon src={item.image} alt={item.alt} />
                  </a>
                ))}
              </Social>
            </Container>
          </Wrapper>
          <Disclaimer className="footnote light">
            {t.footer.contact.disclaimer}
          </Disclaimer>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.darkBrown};
  padding: ${vwMobile(40)} ${vwMobile(16)} ${vwMobile(92)};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h4 {
    margin: ${vwMobile(40)} 0 ${vwMobile(12)};
  }
  @media ${media.tablet} {
    padding: ${vwTablet(60)} ${vwTablet(40)} ${vwTablet(140)};
    h4 {
      margin: ${vwTablet(60)} 0 ${vwTablet(12)};
    }
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(60)} ${vwDesktop(60)} ${vwDesktop(40)};
    text-align: left;
    .logo {
      order: 2;
    }
    h4 {
      margin: 0 0 ${vwDesktop(12)};
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${media.desktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
`;

const Logo = styled.img`
  display: block;
  width: ${vwMobile(106)};
  @media ${media.tablet} {
    width: ${vwTablet(124)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(124)};
  }
`;

const Social = styled.div`
  display: flex;
  > a:nth-of-type(2) {
    margin: 0 ${vwMobile(20)};
  }
  @media ${media.tablet} {
    > a:nth-of-type(2) {
      margin: 0 ${vwTablet(20)};
    }
  }
  @media ${media.desktop} {
    justify-content: flex-end;
    a > img {
      transition: ${({ theme }) =>
        `${theme.animation.time} ${theme.animation.timingFunction}`};
    }
    a:hover > img {
      opacity: 0.8;
    }
    > a:nth-of-type(2) {
      margin: 0 ${vwDesktop(12)};
    }
  }
`;

const Contact = styled.div`
  a {
    display: block;
  }
  @media ${media.desktop} {
    order: 1;
    width: ${vwDesktop(237)};
    text-align: left;
    a {
      transition: ${({ theme }) =>
        `${theme.animation.time} ${theme.animation.timingFunction}`};
    }
    a:hover {
      opacity: 0.8;
    }
  }
`;

const Container = styled.div`
  @media ${media.desktop} {
    order: 3;
    width: ${vwDesktop(237)};
    text-align: right;
  }
`;

const Icon = styled.img`
  display: block;
  width: ${vwMobile(28)};
  height: ${vwMobile(28)};
  @media ${media.tablet} {
    width: ${vwTablet(28)};
    height: ${vwTablet(28)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(28)};
    height: ${vwDesktop(28)};
  }
`;

const Disclaimer = styled.p`
  margin-top: ${vwMobile(40)};
  opacity: 0.7;
  @media ${media.tablet} {
    margin-top: ${vwTablet(60)};
  }
  @media ${media.desktop} {
    margin: ${vwDesktop(60)} auto 0;
    width: ${vwDesktop(920)};
    text-align: center;
  }
`;

export default Info;
