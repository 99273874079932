export default [
  {
    text: 'Home',
    to: '/',
    notOnHome: true,
    image: require('src/assets/images/menu/hero-william-merritt-chase-painting.jpeg'),
  },
  {
    text: 'philosophy',
    to: '/philosophy',
    image: require('src/assets/images/menu/hero-artist-in-grand-hall.jpeg'),
  },
  {
    text: 'creation',
    to: '/creation',
    image: require('src/assets/images/menu/hero-painting-on-canvas.jpg'),
  },
  {
    text: 'LOCATION',
    to: '/location',
    image: require('src/assets/images/menu/hero-kerrisdale-aerial.jpeg'),
  },
  {
    text: 'contact',
    to: '/contact',
    image: require('src/assets/images/menu/hero-lobby-concierge.jpg'),
  },
  {
    text: 'register',
    to: '/register',
    image: require('src/assets/images/menu/hero-interior-kitchen.jpg'),
    register: true,
  },
];
