import React from 'react';
import styled from 'styled-components';
import Intl from 'src/components/Intl';

import Page from 'components/Page';
import Hero from 'components/Hero';
import GoogleMapBlock from 'components/GoogleMapBlock';

import vw from 'src/styles/utils';

const Contact = () => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            <Page>
              <Hero subheader={t.contact.subheader} header={t.contact.header}>
                <HeroImage
                  src={require('src/assets/images/contact/illustration-bell.png')}
                  alt=""
                />
              </Hero>
              <Space />
              <GoogleMapBlock
                title={t.contact.vancouver.title}
                phone="604-620-8295"
                email="sales@gryphonhousevancouver.com"
                address={
                  <>
                    6191 West Boulevard
                    <br />
                    Vancouver, BC
                    <br />
                    Canada V6M 3X3
                  </>
                }
                zoom={13}
                center={{
                  lat: 49.230134,
                  lng: -123.156125,
                }}
                logoCoord={{
                  lat: 49.230134,
                  lng: -123.156125,
                }}
                directions="6191+West+Blvd,+Vancouver,+BC+V6M+3X3/@49.23012,-123.1583194,17z/data=!3m1!4b1!4m5!3m4!1s0x5486737cccf3c9cb:0x9a8711b5ef8db255!8m2!3d49.23012!4d-123.1561254"
              />
              <GoogleMapBlock
                title={t.contact.hk.title}
                phone="+852-2156-9111"
                email="hkinfo@gryphonliving.com"
                address={
                  <>
                    4/F Lee Garden Three, 1 Sunning Road,
                    <br /> Causeway Bay, Hong Kong
                  </>
                }
                zoom={14.5}
                center={{
                  lat: 22.27801,
                  lng: 114.185094,
                }}
                logoCoord={{
                  lat: 22.27801,
                  lng: 114.185094,
                }}
                directions="Spaces+-+Hong+Kong,+Spaces+Lee+Garden+3/@22.2779997,114.1828896,17z/data=!3m1!4b1!4m5!3m4!1s0x340401207d42d21f:0xc2f5199e2b217103!8m2!3d22.2779997!4d114.1850836"
              />
            </Page>
          </Root>
        );
      }}
    </Intl>
  );
};

const Root = styled.div``;

const HeroImage = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  ${vw('width', 50, 99)}
  ${vw('bottom', 120, 150, 110)}
`;

const Space = styled.div`
  ${vw('padding-top', 80)}
`;

export default Contact;
