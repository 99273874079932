import React, { useState, useEffect, useRef } from 'react';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

import Header from './elements/Header';
import Overlay from './elements/Overlay';

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const $overlay = useRef();

  useEffect(() => {
    return () => clearAllBodyScrollLocks();
  }, []);

  const handleMenu = () => {
    if (isOpen) {
      enableBodyScroll($overlay.current);
    } else {
      disableBodyScroll($overlay.current);
    }
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    enableBodyScroll($overlay.current);
    setIsOpen(false);
  };

  return (
    <>
      <Header
        handleMenu={handleMenu}
        isMenuOpen={isOpen}
        handleClose={handleClose}
      />
      <Overlay active={isOpen} closeMenu={handleClose} overlayRef={$overlay} />
    </>
  );
};

export default Menu;
