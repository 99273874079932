import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const RoundButton = (props) => {
  const { transparent, text, to, handleClick } = props;
  return (
    <Root
      as={handleClick ? 'button' : Link}
      to={to}
      className={`button ${transparent ? 'purple' : 'light'}`}
      transparent={transparent}
      onClick={handleClick ? handleClick : (f) => f}
    >
      {text}
    </Root>
  );
};

RoundButton.propTypes = {
  transparent: PropTypes.bool,
  text: PropTypes.string,
  to: PropTypes.string,
  handleClick: PropTypes.func,
};

const Root = styled.button`
  padding: ${vwMobile(6)} ${vwMobile(16)};
  border-radius: ${vwMobile(14)};
  border: solid 1px ${({ theme }) => theme.color.purple};
  background-color: ${(props) =>
    props.transparent ? 'transparent' : props.theme.color.purple};
  @media ${media.tablet} {
    padding: ${vwTablet(9)} ${vwTablet(24)};
    border-radius: ${vwTablet(18)};
  }
  @media ${media.desktop} {
    min-width: 120px;
    text-align: center;
    padding: ${vwDesktop(9)} ${vwDesktop(24)};
    border-radius: ${vwDesktop(18)};
    transition: ${({ theme }) =>
      `${theme.animation.time} ${theme.animation.timingFunction}`};
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.color.taupe};
      background-color: ${(props) =>
        props.transparent ? props.theme.color.purple : '#4a152f'};
    }
  }
`;

export default RoundButton;
