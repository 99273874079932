import React from 'react';

export default {
  switchLang: '中文',
  menu: [
    {
      text: '主页',
      title: (
        <>
          在林畔 
          <br />
          见未来
        </>
      ),
    },
    {
      text: '企业哲学',
      title: (
        <>
          艺术，
          <br />
          扎根于此
        </>
      ),
    },
    {
      text: '完美呈现',
      title: (
        <>
          匠心独具
          <br />
          大师之作
        </>
      ),
    },
    {
      text: '地理位置',
      title: (
        <>
       <span>Kerrisdale</span><br />
       的永恒传说
         
          
        </>
      ),
    },
    {
      text: '联系我们',
      title: (
        <>
        我们很高兴<br />
        能为您服务

        </>
      ),
    },
    {
      text: '注册/登记',
      title: (
        <>
          立即登记
          
        </>
      ),
    },
  ],
  register: '立即登记',
  home: {
    hero: {
      title: (
        <>
          在林畔 
          <br />
          见未来
        </>
      ),
    },
    intro: {
      subTitle: '即将到来',
      title: (
        <>
          乘着阁睿的羽翼， <br />蜚声国际的精致生活理念<br />降临于<span>Kerrisdale</span>。
        </>
      ),
      text:
        '在世界各大顶级都市 ，公寓住宅俨然成为一种高雅的艺术形式。如今，温哥华亦跻身此阵营，带来集现代创新与永恒典雅于一体的世界级住宅。在这个富丽堂皇、安静又显赫的温西社区，在这个位于林荫大道之端又与城市紧密相连的地方，一种富于人文荟萃的都市生活蔚然成风，并欣欣向荣地朝着鼎盛之态发展。',
    },
    linkBlock: {
      subtitle: '企业哲学',
      title: (
        <>
          艺术，扎根于此
        </>
      ),
      body:
        '在这个精挑细选的地段，阁睿将过去25年从全球顶级建筑中汲取的灵感与艺术家主导的专业设计融于一体，打造了辉煌的建筑杰作。',
      button: '了解详情',
    },
    contentBlock: [
      {
        subtitle: '完美呈现',
        title: (
          <h2>
            匠心独具<br />
            大师之作


          </h2>
        ),
        body: (
          <>
            阁睿・壹公馆是遍访世界的最终成果，它是对全球顶级都市建筑进行了全面考察与对最佳生活方式做了深思熟虑之后诞生的。
          </>
        ),
        images: ['home/gryphon-house-storefront.jpg'],
        button: {
          label: '了解详情',
          path: '/creation',
        },
        settings: {
          template: 6,
          rowReverse: false,
        },
      },
      {
        subtitle: '都市文化的新地标',
        title: (
          <h2>
            <span>Kerrisdale</span>
            <br />
            的永恒传说
            
          </h2>
        ),
        body: (
          <>
           古老的参天橡树，历史悠久的深宅大院，魅力奢华的尊贵社区，首屈一指的教育资源，还有那迷人的、变得越发时尚现代的林荫大道。
          </>
        ),
        images: [
          'home/wall-of-flowers.jpg',
          'home/dress-shoes.jpg',
          'home/thomas-hobbs-florist.jpg',
          'home/afternoon-tea.jpg',
        ],
        button: {
          label: '了解详情',
          path: '/location',
        },
        settings: {
          template: 1,
          rowReverse: true,
        },
      },
    ],
  },
  philosophy: {
    subheader: '企业哲学',
    header: (
      <>
        艺术，
        <br />
        扎根于此
        
      </>
    ),
    art: {
      subTitle: '艺术存在于每一天',
      title: (
        <>
         为了使艺术在塑造社区的方式上产生重大影响，我们必须重新思考它在房屋建造中的作用。

        </>
      ),
      text: (
        <>
          阁睿创建了<span>三个部门</span>，以深化我们在安居艺术上的探索，令您尽情沉浸于艺术世界，以全世界艺术家和思想家的眼光和见解开拓您的视野与思想，并为您精心打造每天完美的居住感受。
        </>
      ),
    },
    linkBlock: {
      title: (
        <>
         匠心独具<br />
大师之作

        </>
      ),
      body:
        '凭藉对全球顶级建筑的借鉴，以及与艺术家在安居艺术方面的通力合作，阁睿・壹公馆 为Kerrisdale的盛名再创辉煌。',
      button: '了解详情',
    },
    contentBlock: [
      {
        title: (
          <h3>
            阁睿的企业哲学
          </h3>
        ),
        body: (
          <>
            我们的故事起源于艺术。在过去的25年中，我们的母公司精锐集团改变了我们对建筑的看法。
<br /><br/>
精锐将艺术性贯彻于建筑的方方面面，用创造性的技巧呈现了真正的奢华，以非凡的方式改变了台湾的天际线和生活方式。艺术家被邀请来主导创意过程，高楼大厦拔地而起，成为一种新的艺术形式。

         
          </>
        ),
        images: [
          'philosophy/wooden-thin-panels.jpg',
          'philosophy/jinray-eurostar.jpg',
          'philosophy/jinray-artfair.jpg',
          'philosophy/jinray-resort.jpg',
        ],
        settings: {
          template: 2,
          rowReverse: true,
        },
      },
      {
        title: (
          <h3>
            阁睿的锐变
          </h3>
        ),
        body: (
          <>
           勇猛强健的狮子是精锐的象征，而当它飞越全球，在一块美好的新大陆续写传奇时，狮子变身成希腊神话中半狮半鹫的狮鹫，它代表了精锐的下一代——阁睿。它青出于蓝，既有着狮子的强悍，又有着鹫的敏锐和高远的视角。阁睿将这种具有全球视角的对美好生活与丰富文化的最佳注解带到世界上的顶级街区——纽约的上东区，伦敦的Chelsea和Mayfair。还有，本地的Kerrisdale。

        
          </>
        ),
        images: [
          'philosophy/wooden-wing-panels.jpg',
          'philosophy/westbury-living.jpg',
          'philosophy/musee-sculpture-lady.jpg',
          'philosophy/westbury-rooftop.jpg',
        ],
        // video: {
        //   label: 'Watch Corporate Video',
        //   file: '',
        // },
        settings: {
          template: 3,
          rowReverse: false,
        },
      },
    ],
    cards: [
      {
        logo: 'logo-atelier-white.png',
        logoSize: 2,
        body:
          '阁睿艺创工坊是以艺术家为主导的一项合作，以艺术的方式进行房地产开发。我们广泛地与各类世界级艺术家合作，利用他们强大的创意潜能和独特的视角去打造新的生活艺术形式。',
        background: 'atelier-background.jpg',
      },
      {
        logo: 'logo-royal-curator-white.png',
        logoSize: 0,
        body:
          '我们针对安居艺术推出的最新举措，由忠诚的专业人士为您的各种生活所需提供无微不至的服务，贡献每个人都最想希望得到的：时间。',
        background: 'curator-background.jpg',
      },
      {
        logo: 'logo-musee-white.png',
        logoSize: 1,
        body:
          '为现代艺术家提供的展示平台，陈列着他们各自杰出的作品。',
        background: 'musee-background.jpg',
      },
    ],
  },
  creation: {
    subheader: '完美呈现',
    header: (
      <>
       匠心独具<br />
大师之作
      </>
    ),
    atelier: {
      subTitle: '艺创工坊',
      title: (
        <>
         我们广泛地与各类世界级艺术家合作，由他们强大的创意潜能和独特的视角去打造新的生活艺术形式。
        </>
      ),
      block1: {
        title: <><span>Yamamoto</span><br />建筑事务所</>,
        text:
          '杰出的建筑师和素描画家山本泰三（Taizo Yamamoto）将他的艺术眼光带入阁睿艺创工坊，使艺术与设计融入我们的思想、视野与城市的天际线。',
      },
      block2: {
        title: <><span>False Creek</span>设计集团</>,
        text:
          '著名的温哥华设计公司——False Creek设计集团拥有近三十年的创新及设计经验，为阁睿・壹公馆赋予了沉静而辉煌的光彩。',
      },
    },
    collage: {
      title: (
        <>
         大自然的艺术 
          <br />
          亿万的年沈淀
        </>
      ),
      text:
        '英式风格的欧式莱姆石外墙覆面，为Kerrisdale的未来营造了一种世界性的精致感。这种杰出的石材因其在世界古迹中的应用而闻名，包括西敏寺、白金汉宫和圣保罗大教堂。历史性的优雅与标志性的存在相匹配，就在Kerrisdale。',
    },
    contentBlock: [
      {
        title: (
          <h3>
           全球视野

            
          </h3>
        ),
        body: (
          <>
           从台湾母公司精锐建设世界一流的创新技巧，到阁睿艺术工作室独一无二的合作方式，再到我们走遍全球追寻灵感新形式，阁睿一直在探索最出色的艺术、建筑与生活方式，用以打造具有全球视野的生活，就在Kerrisdale。
          </>
        ),
        images: [
          'creation/west-blvd-aerial.jpg',
          'creation/paris-aerial.jpg',
          'creation/london-apartment-facade.jpg',
          'creation/london-bank-district.jpg',
        ],
        settings: {
          template: 3,
          rowReverse: true,
        },
      },
      {
        subtitle: '建筑风格',
        title: (
          <h3>
            每户住宅<br /> 都是艺术品

          </h3>
        ),
        body: (
          <>
            从伦敦那些时尚与传统相结合的最佳典范汲取灵感，阁睿・壹公馆为Kerrisdale带来了完美的变化。
            <br />
            <br />
            Taizo Yamamoto为阁睿・壹公馆设计了古典的建筑风格，与周边古色古香的社区环境相辅相成，它同时也是描绘社区未来的一种全新视角，为城市增添的一个新的艺术形式。

          </>
        ),
        images: ['creation/taizo-yamamoto-architecture-plans.jpg'],
        settings: {
          template: 6,
          rowReverse: false,
        },
      },
      {
        subtitle: '室内品味',
        title: (
          <h3>
           每个生活<br />
           都是现代钜作
          </h3>
        ),
        body: (
          <>
           凭借False Creek设计集团创造性的细节、高超的设计以及在世界任何地方都罕见的精湛工艺，阁睿・壹公馆成为Kerrisdale尊贵名望与辉煌未来的杰出代表。
          </>
        ),
        images: [
          'creation/coffee-tables.jpg',
          'creation/fcdg-tim.jpg',
          'creation/fcdg-dale.jpg',
        ],
        settings: {
          template: 5,
          rowReverse: true,
        },
      },
    ],
    linkBlock: {
      title: (
        <>
         <span>Kerrisdale</span><br />的永恒传说
        </>
      ),
      text:
        '历史悠久，尊贵永恒。安居的艺术要从讲究位置开始，在温哥华声名显赫的温西地区之中心地段，这一集全球建筑之精髓的新典范为这里古老而尊贵的生活再添新的高雅。',
      buttonLabel:'了解详情',
    },
  },
  location: {
    subheader: '地理位置',
    header: (
      <>
       <span>Kerrisdale</span><br />的永恒传说
      </>
    ),
    contentBlock: [
      {
        title: (
          <h3>
            这里安居的理想之地，这里有着古老而尊贵的生活。
          </h3>
        ),
        body: (
          <>
           宏伟的建筑，连绵的草坪，和家族世代的繁衍生息，构成了Kerrisdale那显而易见的尊荣气质。
在声名显赫的温西地区，这是一个珍贵难得的位置。本地最好的学校聚集于此。咖啡馆和餐馆，服装店和书店，共同在这个城市的核心之地营造了一个高雅文化的小世界——它迎合了都市生活未来发展的各种需求。
          </>
        ),
        images: [
          'location/house-in-treeline.jpg',
          'location/dress-in-shop.jpg',
          'location/students-in-uniform.jpg',
          'location/kerrisdale-clock.jpg',
        ],
        settings: {
          template: 1,
          rowReverse: true,
        },
      },
      {
        title: (
          <h3>
           首屈一指的教育资源
          </h3>
        ),
        body: (
          <>
            从最佳私校到本城领先的公校，再到一所世界顶尖的大学，这个城市最出色的教育机构都集中在此。这是一个构建完美生活，打造光明未来的理想之地。
          </>
        ),
        images: ['location/school-stone-facade.jpg'],
        settings: {
          template: 6,
          rowReverse: false,
        },
      },
      {
        title: (
          <h3>
           世界一流的景观长廊
          </h3>
        ),
        body: (
          <>
           从阁睿・壹公馆走上West Boulevard，这条迷人的林荫路成为世界最长的绿园道公园之一，日新月异的改变使它有如一条美丽的长廊，遍布着艺术装置、咖啡馆和小店铺、文化场所以及令人叹为观止的园林景观。

            <br />
            <br />
            这条著名的林荫路形成了绝佳的公共步道，媲美世界繁华大都市中心区那些最知名的所在——纽约堪称典范的Highline公园，巴塞罗娜那充满活力的Las Ramblas，巴黎市中心令人回味的Avenue Montaigne。而这条大道，就在你的家门口。

          </>
        ),
        images: [
          'location/west-blvd-aerial-closeup.jpg',
          'location/newyork-highline.jpg',
          'location/barcelona-las-ramblas.jpg',
          'location/paris-champs-elysees.jpg',
        ],
        settings: {
          template: 4,
          rowReverse: true,
        },
      },
    ],
  },
  contact: {
    subheader: '联系我们',
    header: (
      <>
        我们很高兴<br />
        能为您服务
      </>
    ),
    directions: '开启地图',
    vancouver: {
      title: '温哥华展销中心',
    },
    hk: {
      title: '香港展销中心',
    },
  },
  footer: {
    register: {
      subheader: '注册/登记',
      header: (
        <>
         探索阁睿・壹公馆的生活美学。
        </>
      ),
      button: '立即登记',
    },
    contact: {
      contact: '联系我们',
      social: 'social',
      disclaimer:
        '此内容仅供信息参考，并非销售合同。销售合同须与披露声明共同提供。如有错漏，不在此限。以上照片、图片仅作为说明，开发商保留随时更改面积、价格、设计和（或者）装潢及设施的权利。',
    },
  },
  registerPage: {
    intro: {
      subTitle: '立即登记',
      title: (
        <>
          探索阁睿・壹公馆的生活美学。
        </>
      ),
    },
    firstName: '名字*',
    lastName: '姓氏*',
    email: '电邮*',
    phone: '电话号码',
    postal: '邮编*',
    howHeard: '您是如何知道我们的？（下拉）',
    howHeardOptions: [
      {
        label: '标牌',
        value: 'Signage',
      },
      {
        label: 'Walk/Drive-by',
        value: 'Walk/Drive-by',
      },
      {
        label: '网上广告',
        value: 'Online Advertising',
      },
      {
        label: '报纸或杂志',
        value: 'Print Advertising',
      },
      {
        label: 'Family/Friend',
        value: 'Family/Friend',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
    realtor: '您是地产经纪吗？*',
    realtorOptions: [
      {
        label: '是',
        value: 'true',
      },
      {
        label: '否',
        value: 'false',
      },
    ],
    typeHome: '您的房型需求？',
    typeHomeOptions: [
      {
        label: '2 Bedroom +',
        value: '2 Bedroom +',
      },
      {
        label: '3 Bedroom +',
        value: '3 Bedroom +',
      },
      {
        label: 'Townhome',
        value: 'Townhome',
      },
      {
        label: 'Penthouse',
        value: 'Penthouse',
      },
      {
        label: 'Retail (CRU)',
        value: 'Retail (CRU)',
      },
    ],
    checkbox:
      '我特此同意接收来自阁睿以及其现在和未来附属企业和子公司的邮件。邮件可包括现在和未来楼盘的价格、建筑平面图、销售情况、活动邀请、通知、企业简报和其他信息。我知道我可以随时停止接收广告电邮。',
    submit: '提交',
    register: '立即登记',
    thankYou:
      '谢谢您的注册。我们的销售代表将很快与您联系。',
    backToHome: '返回首页',
  },
  thankyou: {
    title: (
      <>
       谢谢您的注册。我们的销售代表将很快与您联系。
      </>
    ),
    text:
      '谢谢您的注册。我们的销售代表将很快与您联系。',
    button: '返回首页',
  },
};

