import React from 'react';

export default {
  switchLang: '中文',
  menu: [
    {
      text: '主頁',
      title: (
        <>
          在林畔 
          <br />
          見未來
        </>
      ),
    },
    {
      text: '企業哲學',
      title: (
        <>
          藝術，
          <br />
          扎根於此
        </>
      ),
    },
    {
      text: '完美呈現',
      title: (
        <>
          匠心獨具
          <br />
          大師之作
        </>
      ),
    },
    {
      text: '地理位置',
      title: (
        <>
       <span>Kerrisdale</span><br />
       的永恆傳說
         
          
        </>
      ),
    },
    {
      text: '聯繫我們',
      title: (
        <>
        我們很高興<br />
        能為您服務

        </>
      ),
    },
    {
      text: '註冊/登記',
      title: (
        <>
          立即登記
          
        </>
      ),
    },
  ],
  register: '立即登記',
  home: {
    hero: {
      title: (
        <>
          在林畔 
          <br />
          見未來
        </>
      ),
    },
    intro: {
      subTitle: '即將到來',
      title: (
        <>
          乘著<span>Gryphon</span>的羽翼， <br />蜚聲國際的精緻生活理念<br />降臨於<span>Kerrisdale</span>。
        </>
      ),
      text:
        '在世界各大頂級都市 ，公寓住宅儼然成為一種高雅的藝術形式。如今，溫哥華亦躋身此陣營，帶來集現代創新與永恒典雅於一體的世界級住宅。在這個富麗堂皇、安靜又顯赫的溫西社區，在這個位於林蔭大道之端又與城市緊密相連的地方，一種富於人文薈萃的都市生活蔚然成風，並欣欣向榮地朝著鼎盛之態發展。',
    },
    linkBlock: {
      subtitle: '企業哲學',
      title: (
        <>
          藝術，扎根於此
        </>
      ),
      body:
        '在這個精挑細選的地段，Gryphon將過去25年從全球頂級建築中汲取的靈感與藝術家主導的專業設計融於一體，打造了輝煌的建築傑作。',
      button: '了解詳情',
    },
    contentBlock: [
      {
        subtitle: '完美呈現',
        title: (
          <h2>
            匠心獨具<br />
            大師之作


          </h2>
        ),
        body: (
          <>
            Gryphon House是遍訪世界的最終成果，它是對全球頂級都市建築進行了全面考察與對最佳生活方式做了深思熟慮之後誕生的。
          </>
        ),
        images: ['home/gryphon-house-storefront.jpg'],
        button: {
          label: '了解詳情',
          path: '/creation',
        },
        settings: {
          template: 6,
          rowReverse: false,
        },
      },
      {
        subtitle: '都市文化的新地標',
        title: (
          <h2>
            <span>Kerrisdale</span>
            <br />
            的永恆傳說
            
          </h2>
        ),
        body: (
          <>
           古老的參天橡樹，歷史悠久的深宅大院，魅力奢华的尊貴社區，首屈一指的教育資源，還有那迷人的、變得越發時尚現代的林蔭大道。
          </>
        ),
        images: [
          'home/wall-of-flowers.jpg',
          'home/dress-shoes.jpg',
          'home/thomas-hobbs-florist.jpg',
          'home/afternoon-tea.jpg',
        ],
        button: {
          label: '了解詳情',
          path: '/location',
        },
        settings: {
          template: 1,
          rowReverse: true,
        },
      },
    ],
  },
  philosophy: {
    subheader: '企業哲學',
    header: (
      <>
        藝術，
        <br />
        扎根於此
        
      </>
    ),
    art: {
      subTitle: '藝術存在於每一天',
      title: (
        <>
         為了使藝術在塑造社區的方式上產生重大影響，我們必須重新思考它在房屋建造中的作用。

        </>
      ),
      text: (
        <>
          Gryphon創建了<strong>三個部門</strong>，以深化我們在安居藝術上的探索，令您盡情沉浸於藝術世界，以全世界藝術家和思想家的眼光和見解開拓您的視野與思想，並為您精心打造每天完美的居住感受。
        </>
      ),
    },
    linkBlock: {
      title: (
        <>
         匠心獨具<br />
大師之作

        </>
      ),
      body:
        '憑藉對全球頂級建築的借鑒，以及與藝術家在安居藝術方面的通力合作，Gryphon House 為Kerrisdale的盛名再創輝煌。',
      button: '了解詳情',
    },
    contentBlock: [
      {
        title: (
          <h3>
            <span>Gryphon</span>
            <br />
            的企業哲學
          </h3>
        ),
        body: (
          <>
            我們的故事起源於藝術。在過去的25年中，我們的母公司精銳集團改變了我們對建築的看法。
<br /><br/>
精銳將藝術性貫徹於建築的方方面面，用創造性的技巧呈現了真正的奢華，以非凡的方式改變了台灣的天際線和生活方式。藝術家被邀請來主導創意過程，高樓大廈拔地而起，成爲一種新的藝術形式。

         
          </>
        ),
        images: [
          'philosophy/wooden-thin-panels.jpg',
          'philosophy/jinray-eurostar.jpg',
          'philosophy/jinray-artfair.jpg',
          'philosophy/jinray-resort.jpg',
        ],
        settings: {
          template: 2,
          rowReverse: true,
        },
      },
      {
        title: (
          <h3>
            <span>Gryphon</span>的銳變
          </h3>
        ),
        body: (
          <>
           勇猛強健的獅子是精銳的象征，而當它飛越全球，在一塊美好的新大陸續寫傳奇時，獅子變身成希臘神話中半獅半鷲的獅鷲，它代表了精銳的下一代——Gryphon Development。它青出於藍，既有著獅子的強悍，又有著鷲的敏銳和高遠的視角。Gryphon Development將這種具有全球視角的對美好生活與豐富文化的最佳註解帶到世界上的頂級街區——紐約的上東區，倫敦的Chelsea和Mayfair。還有，本地的Kerrisdale。

        
          </>
        ),
        images: [
          'philosophy/wooden-wing-panels.jpg',
          'philosophy/westbury-living.jpg',
          'philosophy/musee-sculpture-lady.jpg',
          'philosophy/westbury-rooftop.jpg',
        ],
        // video: {
        //   label: 'Watch Corporate Video',
        //   file: '',
        // },
        settings: {
          template: 3,
          rowReverse: false,
        },
      },
    ],
    cards: [
      {
        logo: 'logo-atelier-white.png',
        logoSize: 2,
        body:
          'Gryphon藝創工坊是以藝術家爲主導的一項合作，以藝術的方式進行房地産開發。我們廣泛地與各類世界級藝術家合作，利用他們強大的創意潛能和獨特的視角去打造新的生活藝術形式。',
        background: 'atelier-background.jpg',
      },
      {
        logo: 'logo-royal-curator-white.png',
        logoSize: 0,
        body:
          '我們針對安居藝術推出的最新舉措，由忠誠的專業人士為您的各種生活所需提供無微不至的服務，貢獻每個人都最想希望得到的：時間。',
        background: 'curator-background.jpg',
      },
      {
        logo: 'logo-musee-white.png',
        logoSize: 1,
        body:
          '為現代藝術家提供的展示平台，陳列著他們各自傑出的作品。',
        background: 'musee-background.jpg',
      },
    ],
  },
  creation: {
    subheader: '完美呈現',
    header: (
      <>
       匠心獨具<br />
大師之作
      </>
    ),
    atelier: {
      subTitle: '藝創工坊',
      title: (
        <>
         我們廣泛地與各類世界級藝術家合作，由他們強大的創意潛能和獨特的視角去打造新的生活藝術形式。
        </>
      ),
      block1: {
        title: <><span>Yamamoto</span> 
        <br />
        建築事務所</>,
        text:
          '傑出的建築師和素描畫家山本泰三（Taizo Yamamoto）將他的藝術眼光帶入Gryphon Atelier，使藝術與設計融入我們的思想、視野與城市的天際線。',
      },
      block2: {
        title: <><span>False Creek</span> 
        <br />
        設計集團</>,
        
        text:
          '著名的溫哥華設計公司——False Creek設計集團擁有近三十年的創新及設計經驗，為Gryphon House賦予了沉靜而輝煌的光彩。',
      },
    },
    collage: {
      title: (
        <>
         大自然的藝術 
          <br />
          億萬的年沈淀
        </>
      ),
      text:
        '英式風格的歐式萊姆石外牆覆面，為Kerrisdale的未來營造了一種世界性的精緻感。這種傑出的石材因其在世界古蹟中的應用而聞名，包括西敏寺、白金漢宮和聖保羅大教堂。歷史性的優雅與標誌性的存在相匹配，就在Kerrisdale。',
    },
    contentBlock: [
      {
        title: (
          <h3>
           全球視野

            
          </h3>
        ),
        body: (
          <>
           從台灣母公司精銳建設世界一流的創新技巧，到Gryphon藝術工作室獨一無二的合作方式，再到我們走遍全球追尋靈感新形式，Gryphon一直在探索最出色的藝術、建築與生活方式，用以打造具有全球視野的生活，就在Kerrisdale。
          </>
        ),
        images: [
          'creation/west-blvd-aerial.jpg',
          'creation/paris-aerial.jpg',
          'creation/london-apartment-facade.jpg',
          'creation/london-bank-district.jpg',
        ],
        settings: {
          template: 3,
          rowReverse: true,
        },
      },
      {
        subtitle: '建築風格',
        title: (
          <h3>
            每戶住宅<br /> 都是藝術品

          </h3>
        ),
        body: (
          <>
            從倫敦那些時尚與傳統相結合的最佳典範汲取靈感，Gryphon House為Kerrisdale帶來了完美的變化。
            <br />
            <br />
            Taizo Yamamoto為Gryphon House設計了古典的建築風格，與周邊古色古香的社區環境相輔相成，它同時也是描繪社區未來的一種全新視角，為城市增添的一個新的藝術形式。

          </>
        ),
        images: ['creation/taizo-yamamoto-architecture-plans.jpg'],
        settings: {
          template: 6,
          rowReverse: false,
        },
      },
      {
        subtitle: '室內品味',
        title: (
          <h3>
           每個生活<br />
           都是現代鉅作
          </h3>
        ),
        body: (
          <>
           憑借False Creek設計集團創造性的細節、高超的設計以及在世界任何地方都罕見的精湛工藝，Gryphon House成爲Kerrisdale尊貴名望與輝煌未來的傑出代表。
          </>
        ),
        images: [
          'creation/coffee-tables.jpg',
          'creation/fcdg-tim.jpg',
          'creation/fcdg-dale.jpg',
        ],
        settings: {
          template: 5,
          rowReverse: true,
        },
      },
    ],
    linkBlock: {
      title: (
        <>
         <span>Kerrisdale</span><br />的永恆傳說
        </>
      ),
      text:
        '歷史悠久，尊貴永恆。安居的藝術要從講究位置開始，在溫哥華聲名顯赫的溫西地區之中心地段，這一集全球建築之精髓的新典範為這裡古老而尊貴的生活再添新的高雅。',
      buttonLabel:'了解詳情',
    },
  },
  location: {
    subheader: '地理位置',
    header: (
      <>
       <span>Kerrisdale</span><br />的永恆傳說
      </>
    ),
    contentBlock: [
      {
        title: (
          <h3>
            這裡安居的理想之地，這裡有著古老而尊貴的生活。
          </h3>
        ),
        body: (
          <>
           宏偉的建築，連綿的草坪，和家族世代的繁衍生息，構成了Kerrisdale那顯而易見的尊榮氣質。
在聲名顯赫的溫西地區，這是一個珍貴難得的位置。本地最好的學校聚集於此。咖啡館和餐館，服裝店和書店，共同在這個城市的核心之地營造了一個高雅文化的小世界——它迎合了都市生活未來發展的各種需求。
          </>
        ),
        images: [
          'location/house-in-treeline.jpg',
          'location/dress-in-shop.jpg',
          'location/students-in-uniform.jpg',
          'location/kerrisdale-clock.jpg',
        ],
        settings: {
          template: 1,
          rowReverse: true,
        },
      },
      {
        title: (
          <h3>
           首屈一指的教育資源
          </h3>
        ),
        body: (
          <>
            從最佳私校到本城領先的公校，再到一所世界頂尖的大學，這個城市最出色的教育機構都集中在此。這是一個構建完美生活，打造光明未來的理想之地。
          </>
        ),
        images: ['location/school-stone-facade.jpg'],
        settings: {
          template: 6,
          rowReverse: false,
        },
      },
      {
        title: (
          <h3>
           世界一流的景觀長廊
          </h3>
        ),
        body: (
          <>
           从Gryphon House走上West Boulevard，這條迷人的林蔭路成為世界最長的綠園道公園之一，日新月異的改變使它有如一條美麗的長廊，遍佈著藝術裝置、咖啡館和小店鋪、文化場所以及令人歎為觀止的園林景觀。

            <br />
            <br />
            這條著名的林蔭路形成了絕佳的公共步道，媲美世界繁華大都市中心區那些最知名的所在——紐約堪稱典範的Highline公園，巴塞羅娜那充滿活力的Las Ramblas，巴黎市中心令人回味的Avenue Montaigne。而這條大道，就在你的家門口。

          </>
        ),
        images: [
          'location/west-blvd-aerial-closeup.jpg',
          'location/newyork-highline.jpg',
          'location/barcelona-las-ramblas.jpg',
          'location/paris-champs-elysees.jpg',
        ],
        settings: {
          template: 4,
          rowReverse: true,
        },
      },
    ],
  },
  contact: {
    subheader: '聯繫我們',
    header: (
      <>
        我們很高興<br />
        能為您服務
      </>
    ),
    directions: '開啟地圖',
    vancouver: {
      title: '溫哥華展銷中心',
    },
    hk: {
      title: '香港展銷中心',
    },
  },
  footer: {
    register: {
      subheader: '註冊/登記',
      header: (
        <>
         探索<span>Gryphon House</span>的生活美學。
        </>
      ),
      button: '立即登記',
    },
    contact: {
      contact: '聯繫我們',
      social: 'social',
      disclaimer:
        '此內容僅供信息參考，並非銷售合同。銷售合同須與披露聲明共同提供。如有錯漏，不在此限。以上照片、圖片僅作為說明，開發商保留隨時更改面積、價格、設計和（或者）裝潢及設施的權利。',
    },
  },
  registerPage: {
    intro: {
      subTitle: '立即登記',
      title: (
        <>
           探索<span>Gryphon House</span>的生活美學。
        </>
      ),
    },
    firstName: '名字*',
    lastName: '姓氏*',
    email: '電郵*',
    phone: '電話號碼',
    postal: '郵編*',
    howHeard: '您是如何知道我們的？（下拉）',
    howHeardOptions: [
      {
        label: '標牌',
        value: 'Signage',
      },
      {
        label: 'Walk/Drive-by',
        value: 'Walk/Drive-by',
      },
      {
        label: '網上廣告',
        value: 'Online Advertising',
      },
      {
        label: '報紙或雜誌',
        value: 'Print Advertising',
      },
      {
        label: 'Family/Friend',
        value: 'Family/Friend',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
    realtor: '您是地產經紀嗎？*',
    realtorOptions: [
      {
        label: '是',
        value: 'true',
      },
      {
        label: '否',
        value: 'false',
      },
    ],
    typeHome: '您的房型需求？',
    typeHomeOptions: [
      {
        label: '2 Bedroom +',
        value: '2 Bedroom +',
      },
      {
        label: '3 Bedroom +',
        value: '3 Bedroom +',
      },
      {
        label: 'Townhome',
        value: 'Townhome',
      },
      {
        label: 'Penthouse',
        value: 'Penthouse',
      },
      {
        label: 'Retail (CRU)',
        value: 'Retail (CRU)',
      },
    ],
    checkbox:
      '我特此同意接收來自Gryphon Development以及其現在和未來附屬企業和子公司的郵件。郵件可包括現在和未來樓盤的價格、建築平面圖、銷售情況、活動邀請、通知、企業簡報和其他信息。我知道我可以隨時停止接收廣告電郵。',
    submit: '提交',
    register: '立即登記',
    thankYou:
      '謝謝您的註冊。我們的銷售代表將很快與您聯繫。',
    backToHome: '返回首頁',
  },
  thankyou: {
    title: (
      <>
       謝謝您的註冊。我們的銷售代表將很快與您聯繫。
      </>
    ),
    text:
      '謝謝您的註冊。我們的銷售代表將很快與您聯繫。',
    button: '返回首頁',
  },
};
