import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

import Intl from 'components/Intl';
import RoundButton from 'components/typography/RoundButton';
import FadeUp from 'components/animations/FadeUp';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import mapStyles from 'src/data/map';

const GoogleMapBlock = (props) => {
  const {
    title,
    phone,
    email,
    address,
    directions,
    center,
    logoCoord,
    zoom,
  } = props;

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      return window.open(
        `https://www.google.com/maps/dir//${directions}`,
        '_blank'
      );
    }
  };

  const success = (position) => {
    window.open(
      `https://www.google.com/maps/dir/${position.coords.latitude},${position.coords.longitude}/${directions}`
    );
  };

  const error = () => {
    window.open(`https://www.google.com/maps/dir//${directions}`, '_blank');
  };

  return (
    <Intl>
      {(t) => (
        <FadeUp>
          <Root>
            <div>
              <h4 className="subheader moss">{title}</h4>
              <a href={`tel:${phone}`}>
                <p className="dark">{phone}</p>
              </a>
              <br />
              <a href={`mailto:${email}`}>
                <p className="dark">{email}</p>
              </a>
              <br />
              <br />
              <p>{address}</p>
              <Space />
              <RoundButton
                handleClick={() => getLocation()}
                text={t.contact.directions}
                transparent
              />
            </div>
            <Wrapper>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyBjffVYE4214dRYSEzO3bKSnqP3YAg8rZw',
                }}
                defaultCenter={center}
                zoom={zoom}
                options={{
                  styles: mapStyles,
                  zoomControl: false,
                  fullscreenControl: false,
                }}
              >
                <Logo
                  lat={logoCoord.lat}
                  lng={logoCoord.lng}
                  src={require('src/assets/images/contact/gh-map-logo-highlight.png')}
                />
              </GoogleMapReact>
            </Wrapper>
          </Root>
        </FadeUp>
      )}
    </Intl>
  );
};

GoogleMapBlock.propTypes = {
  title: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  address: PropTypes.object,
  center: PropTypes.object,
};

const Root = styled.div`
  padding: 0 ${vwMobile(16)};
  margin-bottom: ${vwMobile(80)};
  h4 {
    margin-bottom: ${vwMobile(20)};
  }
  
  @media ${media.tablet} {
    padding: 0 ${vwTablet(40)};
    margin-bottom: ${vwTablet(160)};
    h4 {
      margin-bottom: ${vwTablet(40)};
    }
  }
  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${vwDesktop(60)} 0 ${vwDesktop(163)};
    margin-bottom: ${vwDesktop(120)};
    h4 {
      margin-bottom: ${vwDesktop(80)};
    }

    a {
      transition: ${({ theme }) =>
      `${theme.animation.time} ${theme.animation.timingFunction}`};
      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: ${vwMobile(320)};
  position: relative;
  margin-top: ${vwMobile(40)};
  @media ${media.tablet} {
    height: ${vwTablet(520)};
    margin-top: ${vwTablet(60)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(702)};
    height: ${vwDesktop(520)};
    margin-top: 0;
  }
`;

const Logo = styled.img`
  display: block;
  width: ${vwMobile(39)};
  transform: translate(-50%, -50%);
  @media ${media.tablet} {
    width: ${vwTablet(52)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(52)};
  }
`;

const Space = styled.div`
  padding-top: ${vwMobile(20)};
  @media ${media.tablet} {
    padding-top: ${vwTablet(40)};
  }
  @media ${media.desktop} {
    padding-top: ${vwDesktop(40)};
  }
`;

export default GoogleMapBlock;
